import React from 'react'
import {Col, Row} from 'react-bootstrap'
import './style.css'
import {BsTelegram, BsFacebook,BsTwitter,BsInstagram,BsWhatsapp  } from 'react-icons/bs'
import {LuMailPlus} from 'react-icons/lu'
import { IconContext } from "react-icons";

const Footer = () => {
  return (
    <div className='main-footer'>
        <div className="bg-color"/>
            <Row className='footer-row'>
                <Col md={6} sm={12}>
                    <div className='col-one'>
                    <h2 className='need-help'>Need help?</h2>
                    <div className="shape">
                        <div className="shape-text">
                            <h4>Get in touch</h4>
                            <p>To speak to one of our helpful staff, get in touch with the right department or find a branch near you see below.</p>
                       </div>
                    </div>
                    </div>
                    
                </Col>
                <Col md={6} sm={12}>
                        <div className='col-two'>
                            <p>Connect with us in case of any queries
                            or clarification.
                            </p>
                            <h2>Let your art speak!</h2>
                       
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLScaVPRbxRn4FZ7qT_u8OeXQL3a8H5C7Lg3px-uwDrJo7aSZnA/viewform?pli=1" style={{color: 'black'}} className='nav-link ask-invite'>
                                    Ask for an invite
                                </a>
                            <IconContext.Provider value={{ size: '1.5em', className: 'social-icons'}}>
                                <div className="company-socials">
                                    <a href="https://chat.whatsapp.com/EuXKem0qxAxL8NO0BA74iI" target='_blank' rel="noreferrer"><BsWhatsapp/></a>
                                    <a href="https://www.instagram.com/afreative/" target='_blank' rel="noreferrer"><BsInstagram/></a>
                                    <a href="https://twitter.com/afreative" target='_blank' rel="noreferrer"><BsTwitter/></a>
                                    <a href="https://www.facebook.com/Afreative?_rdc=1&_rdr" target='_blank' rel="noreferrer"><BsFacebook/></a>
                                    <a href="https://t.me/+WKA90NAH8lBmM2I0" target='_blank' rel="noreferrer"><BsTelegram/></a>
                                    <a href="mailto:business@afreative.com" target='_blank' rel="noreferrer"><LuMailPlus/></a>
                                </div>
                            </IconContext.Provider>
                        </div>
                </Col>
            </Row>
        
    </div>
  )
}

export default Footer