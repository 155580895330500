import React, {useState} from 'react'
import './style.css'
import {RectangleTwo} from './message-styled'

const MessageSection = () => {
  const initialFormData = {
    name: '',
    email: '',
    message: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add code to send the message here (replace the console.log line)
    console.log(formData);
    setIsSubmitted(true);
    setFormData(initialFormData); // Clear the form after submission
  };

  return (
    <div className='message-div'>
         <RectangleTwo>
          <div className='form-div'>
            <p>If you would like to work with us, input your details below
                and we will get back to you.</p>
     
          <form className='form-letter' onSubmit={handleSubmit}>
            <input 
              type="text" 
              id="name" 
              name="name" 
              placeholder='Name:' 
              value={formData.name}
              onChange={handleChange}
              required/>
            <input
             type="email" 
             id="email" 
             name="email" 
             placeholder='Email:' 
             value={formData.email}
            onChange={handleChange} required/>
            <input
             type="text" 
             name="message" 
             id="text-id" 
             value={formData.message}
              onChange={handleChange}
             placeholder='Write us a message..'/>
            <button type="submit">Submit</button>
          </form>
          {isSubmitted && (
            <p className="success-message">Message sent! <br /> We'll get back to you shortly..</p>
          )}
          </div>
        </RectangleTwo>
    </div>
  )
}

export default MessageSection