import React from 'react'
import creativesimg from '../../images/creativesimg.jpeg'
import businessimg from '../../images/business.jpeg'
import './style.css'

const WeServeSection = () => {
  return (
    <div className='weserve-div'>
        <h3 className="weserve-title">Who We Serve</h3>

        <div className="weserve-content">
            <div className="creatives-div">
                <img src={creativesimg} alt="" className='creatives-img' />
                <p>
                <h5>Creatives:</h5>  <br /> We bring together professionals across diverse creative industries in Africa. Whether you're an artist, musician, filmmaker, designer, or creator in any field, our platform is tailored to your unique needs.
                </p>
            </div>
            <div className="business-div">
            <img src={businessimg} alt="" className='business-img' />
                <p>
                <h5>Businesses and individuals:</h5>  <br /> No matter your sector – from advertising to tech, Non-profit to finance – we provide the connections and resources you need to drive success.
                </p>
            </div>
        </div>
    </div>
  )
}

export default WeServeSection