import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './style.css';
const responsive = {
  desktop: {
    breakpoint: { max: 1200, min: 992 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 992, min: 568 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 568, min: 300 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};


const UpcomingMasterclass = () => {
    const [error, setError] = useState(null);
  const [masterclasses, setMasterclasses] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:1337/api/upcoming-masterclasses?populate=Upcomingmasterclass")
      .then(({ data }) => setMasterclasses(data.data))
      .catch((error) => setError(error));
  }, []);

  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }


  return (
    <div className="carousel-container">
  <Carousel
        responsive={responsive}
        // autoPlay={true}
        swipeable={true}
        draggable={true}
        // showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
        removeArrowOnDeviceType={["mobile"]}
      >
         {masterclasses.map(({id, attributes})=>(
        <div key={id} className='upcoming-webinar carousel-item'>
                <img  className='carousel-image' src={`http://localhost:1337${attributes.Upcomingmasterclass.data.attributes.url}`} alt='' />
              </div>
                 ))}
 </Carousel>
    </div>
  )
}

export default UpcomingMasterclass