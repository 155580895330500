import {BrowserRouter, Route, Routes} from 'react-router-dom';
import HomePage from './pages/home/home-page'
import TheBox from './pages/thebox/the-box'
import ExtraValue from './pages/extravalue/extra-value'
import AskInvite from './pages/askinvite/ask-invite'
import MainBlog from './pages/blog/MainBlog';
import EventsPage from './pages/events/EventsPage';
import BookingsPage from './pages/events/BookingsPage';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ScrollToTopButton from './components/scroll/ScrollToTopButton';




function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTopButton/>
          <Routes>
              <Route path='/' Component={HomePage}/>
              <Route path='pages/extra-value' Component={ExtraValue}/>
              <Route path='pages/the-box' Component={TheBox}/>
              <Route path='pages/MainBlog' Component={MainBlog}/>
              <Route path='pages/EventsPage' Component={EventsPage}/>
              {/* <Route path='pages/ask-invite' Component={AskInvite}/> */}
              <Route path='/BookingsPage' Component={BookingsPage}/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
