import React from 'react'
import aboutimage from './event5'
import aboutimage1 from './event4'
import aboutimage2 from './event3'

const TrendingRow = () => {
  return (
    <div>
        <div className="trendingevents-content">
                  <div className="event-div">
                    <h4 className="trendingevent-title">MUSIC</h4>
                    <div className="image-container">
                      <img src={aboutimage} alt="image1"/>
                      <div className="text-overlay">
                        {/* <h4 className='overlay-title'>Unveiling the Magic</h4>
                        <p className="trendingevent-date">20TH SEPT 2023.5:00PM EAT</p> */}
                      </div>
                    </div>
                  </div>
              
             
                  <div className="event-div">
                    <h4 className="trendingevent-title">MUSIC</h4>
                    <div className="image-container">
                      <img src={aboutimage1} alt="image2"/>
                      <div className="text-overlay">
                        {/* <h4 className='overlay-title'>Unveiling the Magic</h4>
                        <p className="trendingevent-date">20TH SEPT 2023.5:00PM EAT</p> */}
                      </div>
                    </div>
                  </div>
              
              
                  <div className="event-div">
                    <h4 className="trendingevent-title">MUSIC</h4>
                    <div className="image-container">
                      <img src={aboutimage2} alt="image3"/>
                      <div className="text-overlay">
                        {/* <h4 className='overlay-title'>Unveiling the Magic</h4>
                        <p className="trendingevent-date">20TH SEPT 2023.5:00PM EAT</p> */}
                      </div>
                    </div>
                  </div>

                  <div className="event-div">
                    <h4 className="trendingevent-title">MUSIC</h4>
                    <div className="image-container">
                      <img src={aboutimage} alt="image4"/>
                      <div className="text-overlay">
                        {/* <h4 className='overlay-title'>Unveiling the Magic</h4>
                        <p className="trendingevent-date">20TH SEPT 2023.5:00PM EAT</p> */}
                      </div>
                    </div>
                  </div>
                </div>
    </div>
  )
}

export default TrendingRow