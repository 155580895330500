import React from 'react'
import { Link } from 'react-router-dom'
import aboutimage from './event6'
import {HiArrowCircleLeft} from 'react-icons/hi'
import {HiArrowCircleRight} from 'react-icons/hi'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const EventCarousel = () => {
  const sliderRef = React.useRef(null);

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const renderArrows = () => (
    <div className="slider-arrow">
      <div className="arrow-btn prev" onClick={handlePrevClick}>
        <HiArrowCircleLeft className='arrow-left'/> 
      </div>
      <div className="arrow-btn next" onClick={handleNextClick}>
        <HiArrowCircleRight className='arrow-right' /> 
      </div>
    </div>
  );

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        arrows: false,
        centerPadding: "50px",
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        swipeToSlide: true,
      responsive:[
        {
          breakpoint: 480,
          settings: {
            centerPadding: "15px"
          }
        }
      ]
      };

  return (
    <div className='eventcarousel-container'>
      {renderArrows()}
         <Slider {...settings} ref={sliderRef}>
                <div className='carousel-div'>
                    <div className="carousel-content">
                      <img className='carousel-img' src={aboutimage} alt=''/>
                    
                        <section className='carousel-description'>
                          <h4>Occupy the Mall Festival</h4>
                          <Link to={'/BookingsPage'}>
                            <button className='carousel-btn' type="submit">BUY TICKET</button>
                          </Link>
                      </section>
                      </div>
                </div>

                <div className='carousel-div'>
                    <div className="carousel-content">
                      <img className='carousel-img' src={aboutimage} alt=''/>
                    
                        <section className='carousel-description'>
                          <h4>Sarakasi Dancers</h4>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquet, metus eget varius suscipit, ex lorem.</p>
                          <Link to={'/BookingsPage'}>
                            <button className='carousel-btn' type="submit">BUY TICKET</button>
                          </Link>
                      </section>
                      </div>
                </div>

                <div className='carousel-div'>
                    <div className="carousel-content">
                      <img className='carousel-img' src={aboutimage} alt=''/>
                    
                        <section className='carousel-description'>
                          <h4>Sarakasi Dancers</h4>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquet, metus eget varius suscipit, ex lorem.</p>
                          <Link to={'/BookingsPage'}>
                            <button className='carousel-btn' type="submit">BUY TICKET</button>
                          </Link>
                      </section>
                      </div>
                </div>

               
              </Slider>
    </div>
  )
}

export default EventCarousel