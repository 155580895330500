import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {RectangleThree, UpskillImage, MiniHeaderThree, MiniheadText} from './home-styled'
import miniheaderbg from '../../images/african-geometric-seamless-pattern-with-grunge-effect-1.png'
import { motion} from "framer-motion"
import { Link } from 'react-router-dom'
import sourceimage3 from '../../images/sourceimage3.png'

const UpskillSection = () => {
  return (
    <div>
        <Row className="upskill-row">
                
                <Col sm={12} md={6} className='order-2 pull-md-6 order-md-frst' style={{position: 'relative'}}>
                    <div>
                   
                  <div
                  
                    className='upskillrow-text'>
                         <MiniHeaderThree>
                          <div className="overlay3"/>
                          <img src={miniheaderbg} alt=''/>
                                <MiniheadText>
                                    Up-Skill
                                </MiniheadText>
                          </MiniHeaderThree>
                          <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={{  type:"spring", stiffness: 100}}
                            variants={{
                              visible:{opacity: 1, transition: { duration: 0.5 }},
                        hidden: { opacity: 0 }
                            }} className="text-div">
                              <p>
                            Knowledge is the spark that ignites greatness!.
                          </p>
                          <p>                 
                            Enter The Box- a treasure trove of knowledge designed to fuel your creative career. You can expand your skills, master new techniques, and unlock your full potential through carefully curated learning experiences. 
                            </p>
                            <p>
                            Join industry experts, renowned artists, and trailblazers as they share their wisdom, insider tips, and invaluable insights. Discover the secrets behind their success and leverage that knowledge to take your craft to unparalleled heights.
                            </p>
                          </motion.div>
                          <a href="https://docs.google.com/forms/d/e/1FAIpQLScaVPRbxRn4FZ7qT_u8OeXQL3a8H5C7Lg3px-uwDrJo7aSZnA/viewform?pli=1" className='upskill-btn' eventkey={2}>
                                Ask for an invite
                        </a>
                        <motion.div 
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 1.0 }}
                          transition={{ type: "spring", stiffness: 100, damping: 10 }}>
                        
                        </motion.div>
                    </div>
                   
                    </div>
                </Col>
                <Col sm={12} md={6} className='order-1 push-md-6 order-md-last' style={{position: 'relative'}}>
          
                    <RectangleThree>
                      <UpskillImage src={sourceimage3} alt=""/>
                    </RectangleThree>
                 
                </Col>
              </Row>
    </div>
  )
}

export default UpskillSection