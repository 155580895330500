import React from 'react'
import { Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { motion} from "framer-motion"
import {RectangleTwo, BackgroundImage, NetworkingImage, MiniHeader, MiniheadText, OverLay} from './home-styled'
import miniheaderbg from '../../images/african-geometric-seamless-pattern-with-grunge-effect-1.png'
import networkimage1 from '../../images/d-wallpaper-hd-wallpaper-i-phone-wallpaper-android-wallpaper-wallpaper-4-k-1.png'
import networkingimage from '../../images/nimage.png'

const NetworkingSection = () => {
  return (
    <div className='networking-div'> 
        <Row className='networking-row'>
        <Col sm={12} md={6} className='network-img' >
            <div>
              <RectangleTwo>
                <div className="overlay-image">
                  <BackgroundImage src={networkimage1} alt="Background Image" />
                  <NetworkingImage src={networkingimage} alt="" />
                </div>
              </RectangleTwo>
            </div>
        </Col>

        <Col sm={12} md={6} style={{position: 'relative'}}>
          <div>
        
          <div
            className='networkrow-text'>
              <MiniHeader>
                <div>
                <img src={miniheaderbg} alt=""/>
                  <OverLay/>
                  <MiniheadText>
                    Networking
                  </MiniheadText>
                </div>
              </MiniHeader>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{  type:"spring", stiffness: 100}}
                variants={{
                  visible:{opacity: 1, transition: { duration: 0.5 }},
                  hidden: { opacity: 0 }
            }} className="networkingtext-div">
                <p>
                Networking has never been more effortless. With Afreative's web platform, you can connect with fellow creatives across Africa and beyond. Engage in meaningful conversations, exchange ideas, and form collaborations that transcend boundaries.
                </p>
                <p>
                From musicians seeking producers to fashion designers looking for photographers, Afreative brings together a diverse community of creatives, fostering a supportive environment for growth and inspiration.
                </p> 
              </motion.div>
              
            </div>
            <motion.div 
              whileTap={{ scale: 1.0 }}
              transition={{ type: "spring", stiffness: 100, damping: 10 }}>
            <Link className='nav-link networking-btn' eventkey={2} to={'/pages/extra-value'}>
                    Learn more
            </Link>
            </motion.div>
          </div>
        </Col>
      </Row>
</div>

  )
}

export default NetworkingSection