import React, {useEffect, useState} from 'react'
import axios from 'axios'
import './style.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 568, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.

  }
};

const WebinarCard = () => {
  const [error, setError] = useState(null);
  const [webinars, setWebinars] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:1337/api/latest-webinars?populate=category,LatestWebinarVideo")
      .then(({ data }) => setWebinars(data.data))
      .catch((error) => setError(error));
  }, []);

  if (error) {
    // Print errors if any
    return <div>An error occured: {error.message}</div>;
  }


  return (
    <div className='carousel-container'>
 <Carousel
        removeArrowOnDeviceType={["mobile"]}
        responsive={responsive}
        // autoPlay={true}
        swipeable={true}
        draggable={true}
        // showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
    {webinars.map(({id, attributes})=>(
      <div className="carousel-item">
      <div key={id} className="webinar-card ">
            <video controls width="100%">
              <source src={`http://localhost:1337${attributes.LatestWebinarVideo.data.attributes.url}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="webinar-details">
              <p className="webinar-category">{attributes.category.data.attributes.name}</p>
              <h3 className="webinar-title">{attributes.WebinarTitle}</h3>
              <p className="webinar-date-time">
                {attributes.Date} | {attributes.Time}
              </p>
              <div className="webinar-stats">
                <div className="ratings">
                  <p>
                      (1k reviews)
                  </p>
                </div>
                <div className="attendees">
                  <p>Attendees</p>
                </div>
              </div>
            </div>
          </div>
          </div>
    ))}
</Carousel>
    </div>
  )
}

export default WebinarCard