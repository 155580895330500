import React from 'react'
import aboutimage from '../../images/carouselevent.jpeg'
import blogimage from '../../images/featuredone.jpeg'
import blogimage3 from '../../images/blogimage2.jpeg'
import blogimage4 from '../../images/blogimage3.jpeg'

const FeaturedBlogs = () => {
  return (
    // <div className="blogs-container">
      <div className='blog-featured-container'>

                <div className="blog-featured-div">
                      <h3 className="featuredblog-category">MUSIC</h3>
                      <div className="blog-image-container">
                        <img src={aboutimage} alt=""/>
                        <div className="blog-text-overlay">
                          <h3 className='featured-overlay'>Unveiling the Magic</h3>
                        </div>
                      </div>
                    </div>
                    
                    <div className="blog-featured-div">
                      <h3 className="featuredblog-category">FILM</h3>
                      <div className="blog-image-container">
                        <img src={blogimage} alt=""/>
                        <div className="blog-text-overlay">
                          <h3 className='featured-overlay'>Unveiling the Magic: The Art of Music Production</h3>
                        </div>
                      </div>
                    </div>

                    <div className="blog-featured-div">
                      <h3 className="featuredblog-category">FASHION</h3>
                      <div className="blog-image-container">
                        <img src={blogimage3} alt=""/>
                        <div className="blog-text-overlay">
                          <h3 className='featured-overlay'>Trendsetting or Timeless: The Fashion Dilemma</h3>
                        </div>
                      </div>
                    </div>

                    <div className="blog-featured-div">
                      <h3 className="featuredblog-category">GENERAL</h3>
                      <div className="blog-image-container">
                        <img src={blogimage4} alt=""/>
                        <div className="blog-text-overlay">
                          <h3 className='featured-overlay'>Trendsetting or Timeless: The Fashion Dilemma</h3>
                        </div>
                      </div>
                    </div>
        </div>
    // </div>
  )
}

export default FeaturedBlogs