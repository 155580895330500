import React from 'react'


const InvestorVideo = ({smartEmbedLink}) => {
  return (
    <div className='investorvideo-div'>
            <div className="investor-content">
                      <p>We curate exceptional projects aimed at pushing the African creative industries forward. See our current projects below and reach out to us directly to support initiatives that resonate.</p>
              <button className='contact-us'>Contact Us</button>   
            </div>
             <div className="embedded-video">
                    <div
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: 'auto',
                        paddingTop: '56.2500%',
                        paddingBottom: 0,
                        boxShadow: '0 2px 8px 0 rgba(63, 69, 81, 0.16)',
                        overflow: 'hidden',
                        borderRadius: '8px',
                        willChange: 'transform',
                      }}
                      className='video-div'
                    >
                      <iframe
                        loading="lazy"
                        title='investorFrame'
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          top: 0,
                          left: 0,
                          border: 'none',
                          padding: 0,
                          margin: 0,
                        }}
                        src="https://www.canva.com/design/DAFqShvdN2Q/view?embed"
                        allowFullScreen
                        allow="fullscreen"
                      />
                    </div>
        </div>
    </div>
  )
}

export default InvestorVideo

