import React from 'react'
import { Link } from 'react-router-dom'
import NavBar from '../../components/nav/Navbar'
import { Row, Col } from 'react-bootstrap'
import './style.css'
import image1 from '../../images/d-wallpaper-hd-wallpaper-i-phone-wallpaper-android-wallpaper-wallpaper-4-k-1.png'
import { Rectangle,RectangleOne, OverLay, MiniHeader, MiniOverLay, MiniheadText, MiniOverLaytwo, MiniHeadertwo, MiniHeaderthree, MiniOverLaythree } from './ev-styled'
import RectangleImage1 from '../../images/untitled-115.png'
import RectangleImage2 from '../../images/she-is-african-american-art-gold-purple-portrait-teal-thomas-williams-2.png'
import serviceimage from '../../images/capture-1-removebg-preview-1.png'
import Footer from '../../components/footer/Footer'
import { PiArrowBendDownRightDuotone } from "react-icons/pi";
import { IconContext } from "react-icons";
import {MdKeyboardArrowRight} from 'react-icons/md'
import MessageSection from '../../components/message/MessageSection'
import InvestorVideo from './InvestorVideo'
import miniheaderbg from '../../images/african-geometric-seamless-pattern-with-grunge-effect-1.png'

const ExtraValue = () => {

  // Function to scroll to a specific section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <div>
      
      <div id='mainpage'>
      <NavBar/>

      <div className="first-div">
        <OverLay/>
            <div className="firstdiv-content">
              <div className="firstdiv-text">
                <h1>Services</h1>
                <p>Bespoke solutions for creators and corporates.</p>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLScaVPRbxRn4FZ7qT_u8OeXQL3a8H5C7Lg3px-uwDrJo7aSZnA/viewform?pli=1" className='service-btn' eventkey={2} >
                  Signup Today
                </a>
              </div>
              <img src={serviceimage} className='service-image' alt="servimg" />
            </div>
      </div>


      <div className="second-div">
          <div className="seconddiv-text">
            <p>Are you a corporate entity seeking to elevate your brand with fresh, innovative talent and ideas, or are you a creative looking to boost visibility and seize new opportunities? Look no further!</p>
            <div className="services-buttons">
              <Link className='service-links investor-opportunities' onClick={() => scrollToSection('investor-id')}>
                    Investor Opportunities
                </Link>
                <Link className='service-links corporate-services' onClick={() => scrollToSection('corporatesquare-id')}>
                    Corporate Services
                </Link>
              <Link className='service-links creator-services' onClick={() => scrollToSection('creatorservices-id')}>
                    Creator services
                </Link>
            </div>
          </div>
      </div>

      <div className="investor-section" id='investor-id'>
              <MiniHeader>
              <div>
              <img src={miniheaderbg} alt=""/>
                <MiniOverLay/>
                <MiniheadText>
                   Investor Opportunities
                </MiniheadText>
              </div>
            </MiniHeader>
        <InvestorVideo/>
      </div>

      <div className="motivation-text">
        <p className="sample-text">" Imagination is the beginning of creation. You imagine what you desire; you will what you imagine, and at last, you create what you will. " 
  - George Bernard Shaw</p>
      </div>

      <div className="third-div" id='corporatesquare-id'>
           
        <div className="image-containertwo">
        <MiniHeadertwo>
              <div>
              <img src={miniheaderbg} alt=""/>
                <MiniOverLaytwo/>
                <MiniheadText>
                    Corporate Services
                </MiniheadText>
              </div>
            </MiniHeadertwo>
          <div className="corporate-design">
              <img src={image1} className='wallpaper' alt="img1" />
              <RectangleOne/>
          </div>  

            <div className="corporate-square" >
              <div className="square-content">
                <h5>Corporate Services</h5>
                <p>We understand the power of creativity in driving business success. We will collaborate with you to develop out-of-the-box campaigns and events that captivate your target audience. </p>
                <p>
                  Our Corporates Services include:
                  <ul className='corporate-list'>
                    <li><PiArrowBendDownRightDuotone  style={{color: '#FBB100'}}/> Find the perfect creative talent for your business. </li>
                    <li><PiArrowBendDownRightDuotone style={{color: '#FBB100'}}/> Elevate your brand with innovative, out-of-the-box ideas.  </li>
                    <li><PiArrowBendDownRightDuotone style={{color: '#FBB100'}}/> Explore investment opportunities in the creative industries.  </li>
                    <li><PiArrowBendDownRightDuotone style={{color: '#FBB100'}}/> Stay updated on industry events and trends.</li>
                  </ul>
                  <button className='corporate-btn'>Tell us your need<MdKeyboardArrowRight className='cashout-icon'/></button>
                  </p>
                </div>
            </div>
        </div>
      </div>

      <div className="motivation-text">
        <p className="sample-text">“ Your network is your net worth. Afreative provides an online platform to make networking easier and offline mixers for that human touch. “</p>
      </div>
   

     
      <div className="fifth-div" id='creatorservices-id'>
         
     <Row className="fifthdiv-content">
            <Col sm={12} md={6} >
              
              <div className="fifth-content">
              <MiniHeaderthree>
              <div>
              <img src={miniheaderbg} alt=""/>
                <MiniOverLaythree/>
                <MiniheadText>
                    Creator Services
                </MiniheadText>
              </div>
            </MiniHeaderthree>
              <p>As a creative, the journey can be long and challenging. At Afreative, we simplify the path for you, making it effortless to gain visibility, connect with fellow creatives, and access invaluable growth opportunities.</p>

              <IconContext.Provider value={{ color: "#FBB100", className: "global-class-name" }}>
              <ol className='creator-list'>
                <li><PiArrowBendDownRightDuotone/> Are you a business looking to find creatives to execute your vision?</li>
                <li><PiArrowBendDownRightDuotone/> Are you a creative looking for other creatives? </li>
                <li><PiArrowBendDownRightDuotone/> Are you a creative industry professional looking for talent?</li>
                <li><PiArrowBendDownRightDuotone/> Sign up to our mailing list to be updated on upcoming events.</li>
              </ol>
              </IconContext.Provider>

                <Link eventkey={2} to={'/pages/ask-invite'}>
                  <button className='btn-three'>Get started here</button>
                </Link>
                </div>
              </Col>

          <Col sm={12} md={6} className='fifthdiv-image'>
            <Rectangle>
                <img src={RectangleImage1} className='rectangle-image1' alt=""  />
                <img src={RectangleImage2} className='rectangle-image2' alt=""  />
              </Rectangle>
          </Col>
       </Row>
      </div>
      

      <div className="fillform-text">
        <p>
        Get out of town and go where creativity thrives. Pair with the perfect team to bring your vision to life, and let your spirit find peace to tell your authentic story. 
        <br /> <br />
        Fill in the form below and let us know what you need.</p>
      </div>

      <div className="sixth-div">
       <MessageSection/>
      </div>

      <Footer/>
      </div>
    </div>
  )
}

export default ExtraValue