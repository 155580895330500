import React, { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import './style.scss';
import { Link, NavLink } from "react-router-dom";
import Logo from '../../images/cropped-logo.png'

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [scrolling, setScrolling] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);


  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [size.width, isMenuOpen]);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingDown = currentScrollPos > prevScrollPos;

      setScrolling(isScrollingDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <header className={`header ${scrolling ? 'scrolling' : ''}`}>
      <div className="content">
        <Link to="/" className="content__logo">
         <img src={Logo} alt="Logo" style={{width:'60%'}} />
        </Link>
        <nav
          className={`content__nav ${isMenuOpen ? "isMenu" : ""}`}
        >
          <ul>
            <li className="nav-list">
              <NavLink
                to={"/"}
                 onClick={closeMenu}>
                Home
              </NavLink>
            </li>
            <li className="nav-list">
              <NavLink
               to={"/pages/extra-value"}
                onClick={closeMenu}>
                Services
              </NavLink>
            </li>
            <li className="nav-list">
              <NavLink
               to={"/pages/the-box"}
                onClick={closeMenu}>
                The Box
              </NavLink>
            </li>
            <li className="nav-list">
              <NavLink
               to={"/pages/EventsPage"}
                onClick={closeMenu}>
                Events
              </NavLink>
            </li>
            <li style={{color: 'black'}}>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScaVPRbxRn4FZ7qT_u8OeXQL3a8H5C7Lg3px-uwDrJo7aSZnA/viewform?pli=1" style={{color: 'black'}}  className="ask__invite" onClick={closeMenu}>
                Ask for an invite
              </a>
            </li>
          </ul>
        </nav>
        <div className="content__toggle">
          {!isMenuOpen ? (
            <BiMenuAltRight onClick={() => setIsMenuOpen(true)} />
          ) : (
            <AiOutlineClose onClick={closeMenu} />
          )}
        </div>
      </div>
    </header>
  );
};

export default NavBar;
