import React from 'react'
import NavBar from '../../components/nav/Navbar'
import { Row, Col} from 'react-bootstrap'
import './style.css'
import Footer from '../../components/footer/Footer'
import WebinarCard from './WebinarCard'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import MasterClasses from './MasterClasses'
import UpcomingWebinar from './UpcomingWebinar'
import UpcomingMasterclass from './UpcomingMasterclass'

const TheBox = () => {
  return (
    <div>
      <div id='mainpage'>
      <NavBar/>
        <div className="section-one">
          <div className="firstbox-div">
                <div className="div-content">
                  <div className="div-text">
                    <h2>The Box</h2>
                    <p>Learn everything you need to know to be the best</p>
                  </div>
                </div>
          </div>
        </div>


      <div className="search-filter-container">
          <Row>
            <Col xs={12} sm={6}>
              <div className="m-1 search-bar">
              <input type="text" placeholder="Search..."/>
              </div>
          </Col>
          <Col xs={12} sm={6}>
          <div className="m-1 filter-dropdown">
            <select>
              <option value="">All</option>
              <option value="option1">Category</option>
              <option value="option2">Most viewed</option>
              </select>
            </div>
          </Col>
          </Row>
          
    </div>


    <div className="latest-webinas">
        <Row className='thebox-rows'>
          <h3> <b>Latest</b> <small>Webinars</small></h3>
          {/* <Slider> */}
              <WebinarCard/>
          {/* </Slider> */}
        </Row>
    </div>

    <div className="upcoming-webinas">
      <Row className='thebox-rows'>
       <h3><b>Upcoming</b> <small>Webinars</small></h3> 
        {/* <Slider> */}
          <UpcomingWebinar/>
          {/* </Slider> */}
      </Row>
    </div>

    <div className="Latest-masterclass">
      <Row className='thebox-rows'>
       <h3><b>Latest</b> <small>Masterclasses</small></h3> 
       <MasterClasses/>
      </Row>
    </div>

    <div className="upcoming-masterclass">
      <Row className='thebox-rows'>
       <h3><b>Upcoming</b> <small>Masterclasses</small></h3> 
       {/* <Carousel> */}
            <UpcomingMasterclass/>
          {/* </Carousel> */}
      </Row>
    </div>
    <Footer/>
    </div>
    </div>
  )
}

export default TheBox