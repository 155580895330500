import React from 'react'
import NavBar from '../../components/nav/Navbar'
import Footer from '../../components/footer/Footer'

import { Link } from 'react-router-dom'
import { motion, AnimatePresence} from "framer-motion"
import './style.css'
import {SourceImage2} from './home-styled'

import sourceimage2 from '../../images/sourceimage2.png'
import partone from '../../images/part1.png'
import parttwo from '../../images/partb.png'
import partthree from '../../images/partc.png'
import NetworkingSection from './NetworkingSection'
import UpskillSection from './UpskillSection'
import ManageProjects from './ManageProjects'
import CardsSection from './CardsSection'
import WeServeSection from './WeServeSection'
import AboutUs from './AboutUs'
import comingsoon from '../../images/dope-wallpaper-1.png'
import MessageSection from '../../components/message/MessageSection'

const HomePage = () => {


  return (
    <div>
        
   <NavBar/> 

     <AnimatePresence>
        <div className='landpage'>
          
              {/* main header */}
              <motion.span  
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ type:"spring", stiffness: 50, damping: 10}}
              variants={{
                visible:{opacity: 1, x:0, duration:"1"},
                 hidden: { opacity: 0,x:20}
              }}>
                <h1 className='main-header'>EXPLORE <br /> AFRICA'S TALENT</h1>
              </motion.span>
              

              {/* landpage images */}
              <motion.div 
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ type:"spring", stiffness: 50, damping: 10}}
              variants={{
                visible:{opacity: 1, x:0, duration:"0.8"},
                 hidden: { opacity: 0,x:-20}
              }}
              className="main-image-container">

                <motion.img  
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.5 },
                }} src={partone} className='land-img' alt="Image 1"/>
                <motion.img 
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.5 },
                }}  src={parttwo} className='land-img2' alt="Image 2"/>
                <motion.img 
                whileHover={{
                    scale: 1.1,
                    transition: { duration: 0.5 },
                  }}  src={partthree} className='land-img3' alt="Image 3"/>
              </motion.div>

              <a className='nav-link btn-one' eventkey={2} href="https://docs.google.com/forms/d/e/1FAIpQLScaVPRbxRn4FZ7qT_u8OeXQL3a8H5C7Lg3px-uwDrJo7aSZnA/viewform?pli=1">
                            Join our Database
                    </a>
                   
           
        </div>

                  {/* cards section */}
        <div className='section-two'>
          <CardsSection/>
          </div>

                  {/* section three */}
          <div className="section-three">
          <div>
              <div className="about-us">
                <AboutUs/>
              </div>


              <div className="weserve-section">
                <WeServeSection/>
              </div>


                  

        {/* section four */}
        <div className='section-four'>

            <div className='talent-div' id='talent-id'>
              <div className="talenttitle-div">
                <h3>COMING SOON!</h3>
                <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{  type:"spring", stiffness: 100}}
                variants={{
                  visible:{opacity: 1, transition: { duration: 0.5 }},
            hidden: { opacity: 0 }
                }}>Afreative's bespoke web platform that provides ACCESS to the African creative industries will be coming your way soon. <br /> <br /> A seamless way to network, find talent, manage projects, learn and grow!</motion.p>
              </div>
              <img src={comingsoon} alt="" className='comingsoon-img' />
              <SourceImage2 src={sourceimage2} alt=''/>
            </div>

            <div className="source-text">
                <h3 className='source-title'>Find the RIGHT TALENT</h3>
               <p style={{marginTop: '15px'}}><b>Discover the Best of African Creativity </b></p> 
                <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{  type:"spring", stiffness: 100}}
                variants={{
                  visible:{opacity: 1, transition: { duration: 0.5 }},
            hidden: { opacity: 0 }
                }}>
                Discover a world of possibilities with our talented pool of creatives. From designers and photographers to writers and musicians, we have a diverse range of professionals ready to bring their expertise to your projects. 

                Whether you need a fresh perspective on a marketing campaign, a captivating visual for your brand, or a brilliant soundtrack to enhance your content, our talented Afreatives are here to elevate your business to new heights. Tap into our talent pool and unleash the creative power that will set your company apart.
                </motion.p>
                {/* <Link to={"/pages/ask-invite"}> */}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLScaVPRbxRn4FZ7qT_u8OeXQL3a8H5C7Lg3px-uwDrJo7aSZnA/viewform?pli=1" className="talent-btn">Ask for invite</a>
                {/* </Link> */}
            </div>

             

          </div>
        </div>

         {/* networking section */}
         <div className="networking" id='network-id'>
               <NetworkingSection/>
            </div>

                    {/* upskill section */}
            <div className="up-skill" id='upskill-id'>
              <UpskillSection/>
            </div>

                    {/* manage projects section */}
            <div className="manage-projects" id='manageprojects-id'>    
              <ManageProjects/>
            </div>
        </div>


          <div className="message-section">
            <MessageSection/>
          </div>
             
        </AnimatePresence>
        <Footer/>
    </div>
  )
}

export default HomePage