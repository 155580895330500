import React from 'react'
import event2 from './event2'
import event3 from './event3'
import event4 from './event4'
import event5 from './event5'
// import event1 from './event1'


const FeaturedRow = () => {
  return (
    <div class="wrapper">
        <div class="one">
        <div className="mainfeature-container">
            <div className="mainfeature-content">
            <img src={event2} alt=""/>
            <div className="featuretext-overlay">
                {/* <h4 className='overlay-title'>Unveiling the Magic</h4>
                <p className="trendingevent-date">20TH SEPT 2023.5:00PM EAT</p> */}
            </div>
            </div>
        </div>
       </div>
       <div class="two">
        <div className="feature-container">
            <img src={event2} alt=""/>
            <div className="text-overlay">
                {/* <h4 className='overlay-title'>Unveiling the Magic</h4>
                <p className="trendingevent-date">20TH SEPT 2023.5:00PM EAT</p> */}
            </div>
        </div>
       </div>
       <div class="three">
        <div className="feature-container">
            <img src={event3} alt=""/>
            <div className="text-overlay">
                {/* <h4 className='overlay-title'>Unveiling the Magic</h4>
                <p className="trendingevent-date">20TH SEPT 2023.5:00PM EAT</p> */}
            </div>
        </div>
       </div>
       <div class="four">
        <div className="feature-container">
            <img src={event4} alt=""/>
            <div className="text-overlay">
                {/* <h4 className='overlay-title'>Unveiling the Magic</h4>
                <p className="trendingevent-date">20TH SEPT 2023.5:00PM EAT</p> */}
            </div>
        </div>
       </div>
       <div class="five">
        <div className="feature-container">
            <img src={event5} alt=""/>
            <div className="text-overlay">
                {/* <h4 className='overlay-title'>Unveiling the Magic</h4>
                <p className="trendingevent-date">20TH SEPT 2023.5:00PM EAT</p> */}
            </div>
        </div>
       </div>
    </div>
  )
}

export default FeaturedRow