import React from 'react'
import './style.css'
import NavBar from '../../components/nav/Navbar'
import { Container} from 'react-bootstrap'
import webinarimg from '../../images/webinars.png'
import showcasesimg from '../../images/showcases.jpeg'
import mixersimg from '../../images/Mixers.jpeg'
import Footer from '../../components/footer/Footer'
import EventCarousel from './EventCarousel'
import FeaturedRow from './FeaturedRow'
import TrendingRow from './TrendingRow'
import MessageSection from '../../components/message/MessageSection'


const EventsPage = () => {


  return (
    <div>
        <NavBar/>

        <div className="events-div">

          <div className="events-landing">
           <h1 className='events-title'>Events</h1>
           <p>Where Moments become Memories</p>

           <input type="search" className='search-btn' id="event-search" placeholder='Search'/>
          </div>
            
            <div className="explore-events">
              <h3 className='exploreevents-title'>Explore Events</h3>
              <div className="categories">
              <div className="simple-column">
                  <div className="circle">
                    <img src={webinarimg} alt="Title 1" />
                  </div>
                  <h4>Webinars</h4>
                  <p>Get valuable insights, practical tips, and connect with experts</p>
                </div>
                <div className="simple-column">
                  <div className="circle">
                    <img src={mixersimg} alt="Title 2" />
                  </div>
                  <h4>Mixers</h4>
                  <p> It's where you can network, have fun, and build meaningful relationships.</p>
                </div>
                <div className="simple-column">
                  <div className="circle">
                    <img src={showcasesimg} alt="Title 3" />
                  </div>
                  <h4>Showcases</h4>
                  <p>See amazing performances, inspiring art, and innovative projects that'll spark your creativity.</p>
                </div>
              </div>
            </div>


          {/* upcoming events */}
            <div className="upcoming-events">
              <h3 className='upcomingevents-title'> <b>Upcoming</b> Events</h3>
              {/* Carousel */}
             <EventCarousel/>
            </div>


            {/* featured events */}
            <Container className="featured-container">
              <div className="featured-events">
                <h3 className="featured-title">Featured Events</h3>
                <FeaturedRow/>
                <button type="submit" className='featured-btn'>View More</button>
              </div>
            </Container>
            


              {/* Trending Events */}
            <div className="trending-events">
              <h3 className="trending-title">Trending Events</h3>
                <TrendingRow/>
            </div>

        </div>

        <div className="sixth-div">
          <MessageSection/>
       </div>
        <Footer/>
    </div>
  )
}

export default EventsPage