import React from 'react'
import aboutimage from '../../images/aboutimage.png'
import aboutimage1 from '../../images/capture-11.png'
import {Rectangle, Text, AboutImage, AboutImage1 } from './home-styled'
import './style.css'

const AboutUs = () => {
  return (
    <div>
        <div className="about-us">
                <Rectangle>
                
                  <Text>
                    <section className='about-text'>
                      <div className="aboutus-content">
                        <h3 className="about-head">About Us</h3>
                        <p>Afreative is a multisided professional platform dedicated to fostering opportunity for the African Creative Industries. <br /> <br /> We bring creative professionals and businesses together in a growth environment, matching talent to opportunity and knowledge. <br /> <br/> Our web platform (Coming Soon) simplifies the whole process. Currently we work with creatives and businesses in our database</p>
                        </div>
                        
                    </section>
                </Text>

                  <div className="parent-div1">
                    <AboutImage1
                    src={aboutimage1} alt=''/>
                    <AboutImage src={aboutimage} alt="" />
                  </div>
                  
                </Rectangle>
                </div>
    </div>
  )
}

export default AboutUs