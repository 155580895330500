import React from 'react'
import exploreimg from '../../images/blog3.jpeg'
import exploreimg2 from '../../images/creativesimg.jpeg'
import {HiArrowCircleLeft} from 'react-icons/hi'
import {HiArrowCircleRight} from 'react-icons/hi'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'

const ExploreSection = () => {
    const sliderRef = React.useRef(null);

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const renderArrows = () => (
    <div className="explore-slider-arrow">
      <div className="explore-arrow-btn arrow-prev" onClick={handlePrevClick}>
        <HiArrowCircleLeft className='explore-arrow-left'/> 
      </div>
      <div className="explore-arrow-btn arrow-next" onClick={handleNextClick}>
        <HiArrowCircleRight className='explore-arrow-right' />
      </div>
    </div>
  );

    const settings = {
      className: "slider variable-width",
      infinite: true,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      responsive:[
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
      };

  return (
    <div>
        <div className="explore-content">

        <div className="explore-header">
            <img src={exploreimg} alt="" srcset="" />
            <div className="explore-text">
                <h4>Welcome to our WONDERLAND!</h4>
                <p>A world where words come to life, ideas dance on the page, and inspiration knows no bounds.</p>
            </div>
        </div>

        <div className="explore-carousel">
            <div className='explorecarousel-container'>
                {renderArrows()}
                    <Slider {...settings} ref={sliderRef}>
                            <div className='explorecarousel-div'>
                                <div className="explorecarousel-item">
                                    <img src={exploreimg2} alt="" srcset="" />

                                    <div className="type-date">
                                        <p className='explore-category'>MUSIC</p>
                                        <p>SEPT 12 2023</p>
                                    </div>

                                    <h4>Unveiling the Magic: The Art of Music Production</h4>
                                    <p>Explore the intricate world of music production, from songwriting to ...</p>

                                    <p>Readers</p>
                                </div>
                            </div>

                            <div className='explorecarousel-div'>
                                <div className="explorecarousel-item">
                                    <img src={exploreimg2} alt="" srcset="" />

                                    <div className="type-date">
                                        <p className='explore-category'>MUSIC</p>
                                        <p>SEPT 12 2023</p>
                                    </div>

                                    <h4>Unveiling the Magic: The Art of Music Production</h4>
                                    <p>Explore the intricate world of music production, from songwriting to ...</p>

                                    <p>Readers</p>
                                </div>
                            </div>

                            <div className='explorecarousel-div'>
                                <div className="explorecarousel-item">
                                    <img src={exploreimg2} alt="" srcset="" />

                                    <div className="type-date">
                                        <p className='explore-category'>MUSIC</p>
                                        <p>SEPT 12 2023</p>
                                    </div>

                                    <h4>Unveiling the Magic: The Art of Music Production</h4>
                                    <p>Explore the intricate world of music production, from songwriting to ...</p>

                                    <p>Readers</p>
                                </div>
                            </div>
                    </Slider>
            </div>
        </div>
        </div>
    </div>
  )
}

export default ExploreSection