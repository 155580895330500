import {styled} from 'styled-components'
import { motion} from "framer-motion"

export const Rectangle = styled.div`
position: relative;
width:850px;
  height: 500px;

  &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 60%;
        height: 80%; /* Set the background color to cover 90% of the height */
        background: #000; /* Replace #00f with your desired background color */
        z-index: 0;

        @media (max-width: 768px) {
          width: 98%;
        height: 70%;
        }
      }

      @media screen and (max-width: 576px) and (max-height: 700px) {
        height: 95vh;
      }

  @media (max-width: 768px) {
    width: 90vw;
    height: 650px;
  }
  
`
export const AboutImage1 = styled(motion.img)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 380px;
  height: auto;

  @media screen and (max-width: 300px) {
    width: 200px !important;
  }
  

  @media screen and (max-width: 768px) {
   width: 250px;
  }
  
`;
export const AboutImage = styled(motion.img)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 300px;
  height: auto;


  @media (max-width: 768px) {
   width: 150px;
  }
  
`;
export const Text = styled(motion.div)`
  color: white;
  margin-top: 30px;
  position: absolute;

  & p {
    font-style: normal;
    width: 45%;
    font-size: 16px;
    line-height: auto;
    z-index: 10;
    position: relative;

    @media screen and (max-width: 400px) {
    font-size: 13px !important; 
  }
    
    @media (max-width: 576px) {
      width: 80%;
      font-size: var(--font-responsive);
    /* font-size: 12px; */
  }

  @media (max-width: 768px) {
    width: 80%;
  } 
  }
 
`;
export const MiniheadText = styled.h3`
color: white;
  width: 70%;
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  line-height: auto;
  z-index: 10;
  position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

`
export const MiniHeader = styled.div`
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 0 50px 50px 0;
  
 
  transform: translateY(-50%);

& img {
  position: absolute;
  display: block;
  width: 200px;
  height: 50px;
  border-radius: 0 50px 50px 0;
}
`
export const OverLay = styled.div`
position: absolute;
width: 100%;
height: 100%;
z-index: 1;
background-color: rgba(195, 64, 103, 0.904);
border-radius: 0 50px 50px 0;
  

  @media (max-width: 768px) {
    width: 100%;
  }
    
`
export const RectangleTwo = styled.div`
position: relative;
margin-left: auto;
margin-right: 5%;
width: 350px;
height: 500px;
flex-shrink: 0;
background: #1C7DE4;
box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.30);

      @media screen and (max-width: 300px) {
        width: 210px !important;
              height: 280px !important;
      }

      @media screen and (max-width: 576px) {
        margin-bottom: 30px;
            }

      @media screen and (max-width: 768px) {
        margin-top: 0px;
        width: 300px;
        height: 370px;
        margin-left: 0;
      }
`
export const BackgroundImage = styled.img`
position: absolute;
  top: -60px;
  left: -10%;
  width: 100%;
  height: 90%;
  object-fit: cover;
  overflow: hidden;

  @media screen and (max-width: 768px) {
        height: 100%;
      }
  
`
export const NetworkingImage = styled.img`
position: absolute;
  top: 170px;
  left: -10%;
  transform: translate(0, -50%);
  max-width: 100%;
  max-height: 100%;

  @media screen and (max-width: 350px) {
    top: 90px !important;
  }

  @media screen and (max-width: 768px) {
        top: 130px;
      }
  
`
export const MiniHeaderTwo = styled.div`
  position: relative;
  top: 50px;
  margin-right: auto;
  width: 200px;
  height: 50px;
  border-radius: 0px 50px 50px 0px;
  
  & img {
  position: absolute;
  display: block;
  width: 200px;
  height: 50px;
  border-radius: 0px 50px 50px 0px;
}

& div {
  position: absolute;
width: 100%;
height: 100%;
z-index: 1;
background: rgba(251, 176, 0, 0.966);
border-radius: 0px 50px 50px 0px;
}
`
export const SourceImage2 = styled.img`
position: absolute;
  right: 0;
  top: 0;
  width: 250px;
  height: auto;
  z-index: 9;

  @media (max-width: 300px) {
    width: 150px !important;
  }

  @media (max-width: 576px) {
    width: 150px ;
    bottom: 0 !important;
    top: auto;
  }

`
export const MiniHeaderThree  =styled.div`
  position: relative;
  margin-left: var(--padding-xxl);
  width: 200px;
  height: 50px;
  border-radius: 0 50px 50px 0;

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }

  & img {
  position: absolute;
  display: block;
  width: 200px;
  height: 50px;
  border-radius: 0 50px 50px 0;
}
& div {
  position: absolute;
width: 100%;
height: 100%;
z-index: 1;
background: rgba(213, 80, 28, 0.904);
border-radius: 0 50px 50px 0;
}
`
export const RectangleThree = styled.div`
position: relative;
margin-right: auto;
margin-left: 5%;
width: 300px;
height: 450px;
flex-shrink: 0;
background: #FBB100;
box-shadow: 10px 10px 40px 10px rgba(0, 0, 0, 0.30);

    @media screen and (max-width: 576px) {
      width: 210px;
        height: 316px; 
      }
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
`
export const UpskillImage = styled.img`
position: relative;
  left: 20%;
  top: 10%;
  width: 95%;
height: auto;
object-fit: contain;
object-position: center;
`
export const MiniHeaderFour = styled.div`
  position: relative;
  margin-bottom: 15px;
  width: 250px;
  height: 50px;
  border-radius: 0px 50px 50px 0px;
  background: rgba(28, 125, 228, 0.80);

  & img {
  position: absolute;
  display: block;
  width: 250px;
  height: 50px;
  border-radius: 0px 50px 50px 0px;
}     
& div {
  position: absolute;
width: 100%;
height: 100%;
z-index: 1;
background: rgba(28, 125, 228, 0.904);
border-radius: 0px 50px 50px 0px;
}

@media screen and (max-width: 768px) {
      margin-top: 50px;
      }
`
export const RectangleFour = styled.div`
position: relative;
margin-left: auto;
margin-right: 5%;
width: 350px;
height: 470px;
flex-shrink: 0;
background: #D5511C;
box-shadow: 10px 10px 40px 10px rgba(0, 0, 0, 0.30);

      @media screen and (max-width: 576px) {
        width: 250px;
        height: 300px;
      }
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }

`
export const ProjectImage = styled.img`
 position: relative;
  top: -45px; 
  width: 300px;
  height: auto;

@media (max-width: 576px) {
  width: 220px;
  height: auto;
  }
`
export const SquaresContainer = styled.div`
position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
`;



export const Square = styled.div`
  width: 11rem;
  height: 11rem;
  padding: 20px;
  margin: 5%;
  position: absolute;
  border-radius: 40px;

  @media (max-width: 576px) {
    width: 10rem;
  height: 10rem;
  }

`;

export const Content = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
  height: 100%;

  & h4 {
    z-index: 99;
    font-weight: bolder;
  }
  & p {
    z-index: 99;
    font-size: 0.9rem;
  }
`;

