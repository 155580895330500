import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './style.css';
const responsive = {
  desktop: {
    breakpoint: { max: 1200, min: 992 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 992, min: 568 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 568, min: 300 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const UpcomingWebinar = () => {
  const [error, setError] = useState(null);
  const [webinars, setWebinars] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:1337/api/upcoming-webinars?populate=upcomingwebinars")
      .then(({ data }) => setWebinars(data.data))
      .catch((error) => setError(error));
  }, []);

  if (error) {
    return <div>An error occurred: {error.message}</div>;
  }

  return (
    <div className="carousel-container">
 <Carousel
        responsive={responsive}
        // autoPlay={true}
        swipeable={true}
        draggable={true}
        // showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
        removeArrowOnDeviceType={["mobile"]}
      >
        {webinars.map(({ id, attributes }) => (
          <div className="carousel-item">
          <div key={id} className='upcoming-webinar '>
            <img
              className='carousel-image'
              src={`http://localhost:1337${attributes.upcomingwebinars.data[0].attributes.url}`}
              alt=''
            />
          </div>
          </div>
        ))}
</Carousel>
    </div>
  );
};

export default UpcomingWebinar;
