import styled from "styled-components";


export const Rectangle = styled.div`
position: relative;
/* margin-left: auto; */
width: 400px;
height: 550px;
flex-shrink: 0;
background: #E6801C;
transition: 0.4s ease-in-out;
overflow: hidden;
margin-left: 5%;

  @media (max-width: 576px) {
    margin-top: var(--margin);
  }

  @media (max-width: 768px) {
    margin-left: 0;
    width: 300px;
    height: 400px;
  }

`
export const RectangleOne = styled.div`
    width: 484px;
height: 677px;
background: #000000; 
transition: 0.4s ease;
overflow: hidden;

`

export const OverLay = styled.div`
position: absolute;
width: 80%;
height: 100%;
z-index: 1;
background: rgba(28, 125, 228, 0.747);

  @media (max-width: 768px) {
    width: 100%;
  }
    
`
export const MiniHeader = styled.div`
  position: relative;
  width: 240px;
  height: 50px;
  border-radius: 0 50px 50px 0;
  margin-top: var(--margin-lg);


& img {
  position: absolute;
  display: block;
  width: 240px;
  height: 50px;
  border-radius: 0 50px 50px 0;
}
`
export const MiniOverLay = styled.div`
position: absolute;
width: 100%;
height: 100%;
z-index: 1;
background-color: rgba(230, 129, 28, 0.897);
border-radius: 0 50px 50px 0;
  

  @media (max-width: 768px) {
    width: 100%;
  }
    
`
export const MiniheadText = styled.h3`
color: white;
  width: 100%;
  font-style: normal;
  font-size: var(--header-small);
  padding: var(--padding);
  font-weight: 500;
  line-height: auto;
  z-index: 10;
  position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

`

export const MiniHeadertwo = styled.div`
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 50px 0 0 50px;
  margin-left: auto;
  margin-top: var(--margin-lg);

& img {
  position: absolute;
  display: block;
  width: 200px;
  height: 50px;
  border-radius: 50px 0 0 50px;
}
`
export const MiniOverLaytwo = styled.div`
position: absolute;
width: 100%;
height: 100%;
z-index: 1;
background-color: rgba(28, 125, 228, 0.87);
border-radius: 50px 0 0 50px;
  

  @media (max-width: 768px) {
    width: 100%;
  }
    
`

export const MiniHeaderthree = styled.div`
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 0 50px 50px 0;

& img {
  position: absolute;
  display: block;
  width: 200px;
  height: 50px;
  border-radius: 0 50px 50px 0;
}
`
export const MiniOverLaythree = styled.div`
position: absolute;
width: 100%;
height: 100%;
z-index: 1;
background-color: rgba(213, 80, 28, 0.897);
border-radius: 0 50px 50px 0;
  

  @media (max-width: 768px) {
    width: 100%;
  }
    
`