import React from 'react'
import { NavLink } from 'react-router-dom'
import cardimage1 from '../../images/centralized-network.png'
import cardimage2 from '../../images/innovation.png'
import cardimage3 from '../../images/member.png'
import cardimage4 from '../../images/schoolboy-at-a-desk.png'
import investimg from '../../images/invest.png'

const CardsSection = () => {

     // Function to scroll to a specific section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <div>
        <div className='cards-section'>
            <div className="cards-wallpaper"></div>
            <div className='cards-div'>
              
              <div className="card-details">
                  <div className='cards-one cards-source'  onClick={() => scrollToSection('talent-id')}>
                    <div className="card-content source-card">
                        <img className='row-image' src={cardimage1} alt=""/>
                    </div>
                  </div>
                  <h3 className="cards-title">Source Talent</h3>
            </div>
              
            <div className="card-details">
                  <div className='cards-one cards-network' onClick={() => scrollToSection('network-id')}>
                  <div className="card-content network-card">
                    <img className='row-image' src={cardimage2} alt=""/>
                    </div>
                  </div>
                  <h3 className="cards-title">Network</h3>
                </div>
               
                  <div className="card-details">
                    <div className='cards-one cards-upskill' onClick={() => scrollToSection('upskill-id')}>
                    <div className="card-content upskill-card">
                        <img className='row-image' src={cardimage3} alt=""/>
                        </div>
                    </div>
                    <h3 className="cards-title">Upskill</h3>
                  </div>
              
                  <div className="card-details">
                    <div className='cards-one cards-manage' onClick={() => scrollToSection('manageprojects-id')}>
                    <div className="card-content manageprojects-card">
                        <img className='row-image' src={cardimage4} alt="" />
                        </div>
                    </div>
                    <h3 className="cards-title">Manage Projects</h3>
                  </div>
               
                  <div className="card-details">
                    <NavLink
               to={"/pages/extra-value"} style={{textDecoration: 'none', color: '#000'}}>
                    <div className='cards-one cards-invest' >
                    <div className="card-content invest-card">
                        <img className='row-image' src={investimg} alt="" />
                        </div>
                    </div>
                    <h3 className="cards-title">Invest</h3>
                    </NavLink>
                  </div>
               
            </div>
          </div>
    </div>
  )
}

export default CardsSection