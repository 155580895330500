import React from 'react'
import aboutimage from '../../images/carouselevent.jpeg'
import blogimage from '../../images/featuredone.jpeg'

const TrendingBlogs = () => {
  return (
    <div className='blog-featured-container'>
        <div className="blog-featured-div">
                    <h3 className="featuredblog-category">MUSIC</h3>
                    <div className="blog-image-container">
                      <img src={aboutimage} alt=""/>
                      <div className="blog-text-overlay">
                        <h3 className='featured-overlay'>Unveiling the Magic</h3>
                      </div>
                    </div>
                  </div>
                  
                  <div className="blog-featured-div">
                    <h3 className="featuredblog-category">FILM</h3>
                    <div className="blog-image-container">
                      <img src={blogimage} alt=""/>
                      <div className="blog-text-overlay">
                        <h3 className='featured-overlay'>Unveiling the Magic: The Art of Music Production</h3>
                      </div>
                    </div>
                  </div>

    </div>
  )
}

export default TrendingBlogs