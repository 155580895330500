import styled from "styled-components";

export const RectangleTwo = styled.div`
position: relative;
top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
width: 60%;
height: 500px;
flex-shrink: 0;
border-radius: 30px;
background: #D5511C;

@media screen and (max-width: 576px) {
  height: 80%;
}
@media screen and (max-width: 768px) {
    width: 90%;
    /* height: 70%; */
  }
`