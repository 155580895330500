import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {RectangleFour, ProjectImage, MiniHeaderFour, MiniheadText} from './home-styled'
import miniheaderbg from '../../images/african-geometric-seamless-pattern-with-grunge-effect-1.png'
import { motion} from "framer-motion"
import { Link } from 'react-router-dom'
import projectimage from '../../images/projectimage.png'

const ManageProjects = () => {
  return (
    <div>
        <Row className="manprojects-row">
                <Col sm={12} md={6} style={{position: 'relative'}}>
                    <div>
                        <RectangleFour>
                         <ProjectImage src={projectimage} alt=""/>
                        </RectangleFour>
                        <img src="" alt="" />
                    </div>
                </Col>
                <Col sm={12} md={6} style={{position: 'relative'}}>
                  <div>
                  <div
                    
                       className='manageprojects-text'>
                         <MiniHeaderFour>
                          <div className="overlay3"/>
                          <img src={miniheaderbg} alt=""/>
                              <MiniheadText>
                                Manage projects
                              </MiniheadText>
                            </MiniHeaderFour>
                            <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{  type:"spring", stiffness: 100}}
                    variants={{
                      visible:{opacity: 1, transition: { duration: 0.5 }},
                hidden: { opacity: 0 }
                    }} className="text-div">
                              <p>
                              Say goodbye to chaos and welcome the era of organized collaboration. Afreative creates an enabling environment where creatives and businesses can come together, exchange ideas, and embark on exciting projects that drive success. Our platform facilitates seamless collaboration, ensuring that creatives and businesses can work harmoniously to achieve their shared goals.
                              </p> 
                              <p>
                              Welcome to a new era of limitless possibilities —the nexus of creativity and efficiency. </p>
                            </motion.div>
                            <motion.div 
                              whileTap={{ scale: 1.0 }}
                              transition={{ type: "spring", stiffness: 100, damping: 10 }}>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLScaVPRbxRn4FZ7qT_u8OeXQL3a8H5C7Lg3px-uwDrJo7aSZnA/viewform?pli=1" className='manageproj-btn' eventkey={2}>
                                    Ask for an invite
                            </a>
                            </motion.div>
                    </div>
                   
                  </div>
                </Col>
              </Row>
    </div>
  )
}

export default ManageProjects