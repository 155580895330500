import React from 'react'
import NavBar from '../../components/nav/Navbar'
import Footer from '../../components/footer/Footer'
import {MdKeyboardArrowRight} from 'react-icons/md'
import './style.css'

const BookingsPage = () => {
  return (
    <div>
    <NavBar/>
        <div className="bookingpage-div">
            <div className="contact-details">
                
                <form action="">
                <h4 className='form-header'>Share Contact Details</h4>
                <div className='input-div'>
                    <input type="text" name="name" id="name" placeholder='First Name'/>
                    <input type="text" name="secondname" id="" placeholder='Last Name'/>
                </div>
                <div className='input-div'>
                    <input type="email" name="email" id="" placeholder='Your Email' />
                    <input type="number" name="phonenumber" id="" placeholder='Phone No'/>
                </div>
                <button className='cashout-btn'>Proceed to cashout <MdKeyboardArrowRight className='cashout-icon'/></button>
                </form>
            </div>
         </div>
        <Footer/>
    </div>
  )
}

export default BookingsPage