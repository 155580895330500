import React from 'react'
import NavBar from '../../components/nav/Navbar'
import "./style.css"
import imageone from '../../images/blog1.jpeg'
import imagetwo from '../../images/blog2.jpeg'
import FeaturedBlogs from './FeaturedBlogs'
import MostRead from './MostRead'
import TrendingBlogs from './TrendingBlogs'
import Footer from '../../components/footer/Footer'
import MessageSection from '../../components/message/MessageSection'
import ExploreSection from './ExploreSection'

const MainBlog = () => {
  return (
    <div>
        <NavBar/>

        <div className="blog-landing">
          <div className="landing-content">

            <div className="text-content">
              <h2 className="blog-header">Blog</h2>
              <p>Insights, Ideas, and Inspiration 🌍✨</p>
            </div>

              <img src={imageone} alt="" className='image-one' />
              <div className="overlay"/>
              <img src={imagetwo} alt="" className='image-two' />

            <input type="search" className='search-btn' name="blogsearch" placeholder='Search' />
          </div>
        </div>


        

        <div className="explore-div">
          <h3 className="blogexplore-header">Explore</h3>

           {/* explore component goes here */}
          <ExploreSection/>
        </div>


        <div className="blogfeatures-section">
          <h4 className="blogfeatures-header">Featured Blogs</h4>
            <div className="featured-content">
              <FeaturedBlogs/>
            </div>
          {/* featured blogs component goes here */}
          <button className='view-more'>View More</button>
        </div>

        <div className="mostread-div">
          <h4 className="mostread-header">Most Read Blogs</h4>
          <MostRead/>
          {/* most read blogs component goes here */}
          <button className='view-more'>View More</button>
        </div>

        <div className="trendingblogs-div">
          <h4 className="trendingblogs-header">Trending Blogs</h4>
          <TrendingBlogs/>
          {/* most read blogs component goes here */}
        </div>

        <MessageSection/>
        <Footer/>
    </div>
  )
}

export default MainBlog